.modal-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}
.modal {
    width: 40%;
    padding: 20px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow:  0 0 4px 2px #ccc;
}
.modal-title {
    text-align: center;
}
.modal-btn {
    background:none;
    border: 0;
    outline: none;
    color: #3DAC78;
    cursor: pointer;
}
.modal-message {
    margin: 20px 0;
}
.modal-btn {
    text-align: right;
}
@media screen and (max-width:991px) {
    .modal {
        width: 70%;
        padding: 20px;
        margin: auto;
        background-color: #fff;
    }
}
@media screen and (max-width:991px) {
    .modal {
        width: 90%;
        padding: 20px;
      
        background-color: #fff;
    }
}