.user-info{
    display: flex;

flex-direction: column;
width: 40%;
margin: 160px auto;

}
.info-card {
    background-color: #fff;
    margin: 20px 0;
    box-shadow: 0 0 4px 2px #ccc;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.email-key {
    font-weight: 600;
}
.email-value {
    font-weight: 400;

}
