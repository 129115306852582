.loading {  z-index: 444; 
 
    width: 100vw; 
    height: 100vh;
     background-color: rgba(0,0,0,0.4);
     position: fixed;
     top: 0;
     left: 0;
;

    }
.loading-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}   
.d-none {
    display: none;
}
