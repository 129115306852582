.start {
    padding:15px 50px;
    border: 0;
    border-radius: 30px;
    color: var(--white);
    font-size: 19px;
    cursor: pointer;
  

    border: 2px solid var(--primary);
    transition: 0.6s;
    background-color: var(--primary);
color: #fff;
}
