

.search-bar-wrapper {
    margin:0    40px;
  width: 450px;
  display: flex;
}

.search-bar {

    border-radius: 3px 0 0 3px;
    padding: 12px 15px   ; 
   flex: 1;
    border:none; 
font-size: 16px;

}
.search-bar-btn {
 

padding: 13px 25px;
cursor: pointer;
    border:0;
    background-color: var(--text-color);
    border-radius: 0 3px 3px 0;
transform: translateY(0.25px);
}
@media screen and (max-width:991px) {
    .search-bar-wrapper {
width: 100%;
margin: 10px 0;

    }
    
}