.footer {
    background-color: var(--primary);
    padding: 40px 20px ;
    margin-top: 30px;
}
.footer-bottom {
    padding:30px 20px;
    background-color: var(--inversePrimary);
}
.footer-copyright-text {
    font-size: 14px;
    display: block;
}
.author {
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;

}
.author-link {
    color: blue;
}
.email-info {
    display: flex;
    align-items: center;
}
.email {
    color: var(--white);
}
.footer-icons {
    margin-top: 10px;
    display: flex;

}
.footer-icon-item {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
width: 40px;
height: 40px;
border-radius: 50%;
background-color: var(--inversePrimary);
}
.footer-icon-item:hover {
    opacity: 0.8;
}