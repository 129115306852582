.submit-btn {
    background-color: #3DAC78;
    width: 100%;
    border: 0;
    padding: 10px;
    border-radius: 3px;
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}