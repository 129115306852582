.home-desc{
margin: 20px 0;
}
.home-title {
    color: var(--primary);
}
.hero-img {
    width: min(100%, 500px);
    height: 300px;
}
.service-title {
    text-align: center;
    font-size: 25px;
    letter-spacing: 1px;
}
.card {
    width: 100%;
    box-shadow: 0 0 10px 3px #ccc;
    padding: 20px;
    border-radius: 10px;

}
.card-paragraph {
margin-top: 5px;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 200;
    color: #555;

}
.cart-title {
    text-align: center;
}