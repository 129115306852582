.all-product-container {

    height: 70vh;
}
.product-title {
    margin-top: 100px;
    text-align: center;
    font-size: 30px;
}
.divider {
    margin: 10px 0;
    background-color: #ccc;
    width: 100%;
    height: 1px;
}

