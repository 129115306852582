.Login-form {

    margin: auto;
    width: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px 1px #ccc;
    border-radius: 5px;
    margin: 150px auto;
}
.form-title {
    text-align: center;
    padding-top: 10px;
}
.form {
    padding: 10px;
  
    
}

@media screen and (max-width:991px) {
    .Login-form {
        width: 100%;
        margin: 75px auto;
    }
    
}
