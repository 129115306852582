
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&family=Roboto:ital,wght@0,400;1,700&display=swap');

:root {
  --primary:#3DAC78;
  --inversePrimary  :#95D5B2;
  --onPrimary:#D8F3DC;
  --text-color:#333333;
  --white:#ffffff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;

}
.container {
 width: 80%;
  margin: 00px auto;
  margin-top: 80px;
  text-align: start;

}
img {
  width: 100%;
}

.p-0{
  padding: 0;
}
.m-0 {
  margin: 0;
}
.no-wrap {
  flex-wrap: nowrap;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.pt-60 {
  padding-top: 60px;
}
.col-1_4,.col-1_3,.col-1_2,.col-full {
  padding: 20px;
}
.col-1_3 {
  width: calc(100% / 3);
}
.col-1_4 {
  width: 25%;
}
.col-1_2 {
  width: 50%;
}
.col-full {
  width: 100%;
}


.web,.web-tablets {
  display: block;

}
.mobile,.only-mobile {
  display: none;
}
.container {
  width: 90%;
}
.align-center {
  align-items: center;
}
@media screen and (max-width:991px) {
  .container {
    width: 90%;
  }
  .mobile {
    display: block;
  }
  .web {
    display: none;
  }
  .col-1_4,.col-1_3 {
    width: 50%;
  }
  .only-mobile {
    display: block;
  }
  .web-tablets {
    display: none;
  }

  .container {
    width: 95%;
    margin-top: 221px;
  }
}
@media screen and (max-width:768px) {
  .col-1_2,.col-1_3,.col-1_4 {
    width: 100%;
  }
}