.Register-form {

    margin: auto;
    width: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px 1px #ccc;
    border-radius: 5px;
    margin: 100px auto;
}
.form-title {
    text-align: center;
    padding-top: 10px;
}
.form {
    padding: 10px;
  
    
}
.error-container {
    display: flex;
    width: 100%;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 20px;
    align-items: center;

}
.error-message {
   color:  #721c24;
}

@media screen and (max-width:991px) {
    .Register-form {
        width: 100%;
    }
    
}
