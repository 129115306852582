
.form-input {
    width: 100%;
   border: 1px solid #a3a3a3;
   padding: 10px;
   border-radius: 3px;
transition: 0.4s;

}
.form-input:focus {
    border: 1px solid #3DAC78;
}