
$inversePrimary:#95D5B2;

.header-bottom {
    background-color: $inversePrimary;
 
padding: 20px;

 }
 @media screen and (max-width:991px) {
.header-bottom {
    margin-top: 181 px;
    width: 100%;
    display: flex;
    padding: 0;

}





 }
 @media screen and (max-width:768px) {
    .header-bottom {
        display: none;
    }
 }