.header {
   background-color: var(--primary);
   color: var(--secondary);
   padding: 20px 10px;
   justify-content: center;

 
}
.header-content {
   display: flex;
   align-items: center;
flex-wrap: wrap;
justify-content: center;
}

.header-title-first-part,.header-title-second-part {
   font-style: italic;
   display: inline-block;
   user-select: none;
}
.header-title-first-part{
   color: var(--white);
}
.header-title-second-part{
   color: var(--onPrimary);
}

.account-wrapper {
   margin-left:  60px;
}
.header-icons-wrapper {
   display: flex;
   align-items:center;

}
.shopping-cart-wrapper {
   position: relative;
   
}
.total-item-wrapper {
   position: absolute;
  right  :-7px;
   top:-10px;
   background-color: crimson;
display: none;
   width: 20px;
   height:20px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items:center;
}
.total-item {

   color: var(--white);
   font-size: 12px;
}

@media screen and (max-width:991px) {
   .header {
      position: fixed;
      top: 0;

      width: 100%;
   }

   .header-content {
      justify-content: space-between;
   }
   .header-search-mobile {
      display: block;
   }

   .account-wrapper {
      justify-content: flex-start;
      display: flex;
   }
   .account-wrapper {
      margin-left: 0;
   }
}
.drawer-container {
   width: 100%;
   height: 100vh;
   position: fixed;
   top: 0;
   transition: 0.5s ease-in-out;
   
   
}
.drawer-close-btn {
   position: absolute;
   right: 15px;
   top: 15px;
   color: var(--white);
   cursor: pointer;
}
.drawer-menu {
width: min(300px,100%);
position: fixed;
top:0;
height: 100vh;
background-color: var(--white);
transition: 0.5s ease-in-out;
z-index: 2;
}
.drawer-top {
   height: 200px;
   background-color: var(--primary);
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
position: relative;
}
.user-email {
   position: absolute;
   bottom: 10px;
   left: 50%;
   transform: translateX(-50%);
   color: var(--white);
   font-size: 13px;
}
#open {
   left: 0;
   z-index: 4;
}
#close {
z-index: 0;
   left: -100%;
}
.drawer-item {
   border-bottom: 1px solid #ccc;
}
.drawer-link {
   padding: 10px;
   display: block;
   color: var(--secondary);
   
}
#opened-container {
   background-color: rgba(0, 0, 0,0.6);
   z-index: 1;
}
#closed-container {
   background-color: rgba(0, 0, 0,0.0);
   z-index: -1;
}
